<template>
  <div>
    <b-row class="align-items-center">
      <!-- Columna para el título -->
      <b-col
        cols="12"
        md="6"
        class="text-center text-md-left mb-2 mb-md-0"
      >
        <h1 class="font-weight-bolder">
          {{ $t('billing') }}
        </h1>
      </b-col>

      <!-- Columna para el botón -->
      <b-col
        cols="12"
        md="6"
        class="text-center text-md-right"
      >
        <b-button
          :to="{ name: 'new-billing' }"
          variant="primary"
        >
          {{ $t('register', { type: $t('billing') }) }}
        </b-button>
      </b-col>
    </b-row>

    <!-- Tabla de facturación -->
    <b-row>
      <b-col>
        <billing-list-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import BillingListTable from '@/views/billing/BillingListTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BillingListTable,
  },
}
</script>

<style scoped>
/* Ajustes de márgenes y espaciado para mejorar la apariencia */
h1 {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  /* Ajustes específicos para pantallas pequeñas */
  h1 {
    font-size: 1.5rem;
  }
}
</style>
