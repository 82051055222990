<template>
  <b-card no-body>
    <b-overlay :show="loading" rounded="sm">
      <b-table :items="billingList" :fields="fields" responsive>
        <!-- Columna personalizada para el índice -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- Columna personalizada para RFC -->
        <template #cell(rfc)="data">
          {{ data.value }}
        </template>

        <!-- Columna para la razón social -->
        <template #cell(razonSocial)="data">
          {{ data.value }}
        </template>

        <!-- Columna para C.P. -->
        <template #cell(cp)="data">
          {{ data.value }}
        </template>

        <!-- Columna para la calle -->
        <template #cell(calle)="data">
          {{ data.value }}
        </template>

        <!-- Columna para Número Exterior -->
        <template #cell(numeroExterior)="data">
          {{ data.value }}
        </template>

        <!-- Columna para Número Interior -->
        <template #cell(numeroInterior)="data">
          {{ data.value || "-" }}
        </template>

        <!-- Columna para Colonia -->
        <template #cell(colonia)="data">
          {{ data.value }}
        </template>

        <!-- Columna para Localidad -->
        <template #cell(localidad)="data">
          {{ data.value }}
        </template>

        <!-- Columna para Municipio -->
        <template #cell(municipio)="data">
          {{ data.value }}
        </template>

        <!-- Columna para Estado -->
        <template #cell(estado)="data">
          {{ data.value }}
        </template>

        <!-- Columna para CFDI -->
        <template #cell(cfdi)="data">
          <b-form-select v-model="data.item.cfdi" :options="cfdiOptions" />
        </template>

        <!-- Columna para Régimen -->
        <template #cell(regimen)="data">
          <b-form-select
            v-model="data.item.regimen"
            :options="regimenOptions"
          />
        </template>

        <!-- Columna para CSF -->
        <template #cell(csf)="data">
          <b-button-group>
            <b-button @click="downloadCSF(data.item.rfc)">
              <feather-icon
                icon="DownloadIcon"
                class="text-white"
                size="18"
                color="#000"
              />
            </b-button>
            <b-button @click="viewCSF(data.item.rfc)">
              <feather-icon
                icon="EyeIcon"
                class="text-white"
                size="18"
                color="#000"
              />
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </b-overlay>
    <b-pagination
      class="mt-1 ml-1"
      :value="pagination.page"
      :total-rows="pagination.total_objects"
      :per-page="pagination.per_page"
      @change="handlePageChange($event)"
    />
  </b-card>
</template>

<script>
import {
  BTable,
  BButton,
  BButtonGroup,
  BPagination,
  BCard,
  BOverlay,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BButtonGroup,
    BPagination,
    BCard,
    BOverlay,
    BFormSelect,

  },
  data() {
    return {
      selectedBilling: {},
      loading: false,
      cfdiOptions: ['01', '02', '03'], // Ejemplo de opciones CFDI
      regimenOptions: ['Régimen General', 'Régimen Simplificado'], // Ejemplo de opciones de Régimen
      billingList: [
        {
          rfc: 'PDY220130LK8',
          razonSocial: 'PLATAFORMA DIGITAL YOC',
          cp: '66612',
          calle: 'LILIES',
          numeroExterior: '431',
          numeroInterior: '',
          colonia: 'VALLE DE LAS PALMAS',
          localidad: 'APODACA',
          municipio: 'APODACA',
          estado: 'NUEVO LEON',
          cfdi: '01',
          regimen: 'Régimen General',
        },
        {
          rfc: 'ESA150916CB7',
          razonSocial: 'EDIFICACIONES SAS',
          cp: '66220',
          calle: 'RIO GUADALQUIVIR ORIENTE',
          numeroExterior: '417',
          numeroInterior: '',
          colonia: 'DEL VALLE',
          localidad: 'SAN PEDRO GARZA GARCÍA',
          municipio: 'SAN PEDRO GARZA GARCÍA',
          estado: 'NUEVO LEON',
          cfdi: '02',
          regimen: 'Régimen Simplificado',
        },
      ],
      fields: [
        { key: 'index', label: '#' },
        { key: 'rfc', label: 'RFC' },
        { key: 'razonSocial', label: 'Razón Social' },
        { key: 'cp', label: 'C.P.' },
        { key: 'calle', label: 'Calle' },
        { key: 'numeroExterior', label: 'Número Exterior' },
        { key: 'numeroInterior', label: 'Número Interior' },
        { key: 'colonia', label: 'Colonia' },
        { key: 'localidad', label: 'Localidad' },
        { key: 'municipio', label: 'Municipio' },
        { key: 'estado', label: 'Estado' },
        { key: 'cfdi', label: 'CFDI' },
        { key: 'regimen', label: 'Régimen' },
        { key: 'csf', label: 'CSF' },
      ],
      pagination: {
        page: 1,
        total_objects: 2,
        per_page: 10,
      },
    }
  },
  methods: {
    handlePageChange(page) {
      this.loading = true
      this.pagination.page = page
      // Lógica para cargar nuevos datos según la página
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    downloadCSF(rfc) {
      console.log(`Descargar CSF para RFC: ${rfc}`)
    },
    viewCSF(rfc) {
      console.log(`Ver CSF para RFC: ${rfc}`)
    },
  },
}
</script>

<style scoped>
/* Estilos adicionales para personalizar la tabla */
</style>
